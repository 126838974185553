import React from "react";
import Store from "../store/store";
import { getAllUsers } from "../store/store";
import { observer } from "mobx-react";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal/Modal";
import { RouteComponentProps, withRouter } from "react-router-dom";
const styles = require("./styles/listUsers_styles.module.scss");
const modal = require("../../styles/styles.module.scss");

interface IModal {
  open?: boolean;
  id?: number;
}

@observer
export default class ParametersUser extends React.Component<RouteComponentProps<any>,IModal> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      id: 0
    };
    getAllUsers();
    // this.getAllTreeView(); // TODO JJ
  }

  //   public getAllTreeView = () => {
  //     const tempArray:any[] = [];
  //     axios.get(Store.wsPath + '/1/treeview/GetAllTreeView').then(response => {
  //         response.data.forEach(data => {
  //             tempArray.push({ id: data.idxDomaine, treeview: data.json })
  //         });
  //         Store.AllTreeViewDomaine = tempArray;
  //     })
  // }

  componentDidMount() {
    // this.getAllUsers();
  }

  public toolbox = (cell: any, row: any) => {
    return (
      <div className={styles.listActionContainer}>
        {Store.userConnected.typeUserLydoc == "admin" ?
          <div className={styles.iconEdit} onClick={() => this.editRow(row)}><Icon style={{fontSize : 20}}>mode_edit</Icon></div>
        :""}
        {Store.userConnected.typeUserLydoc == "admin" ?
          <div className={styles.iconEdit} onClick={() => this.modalOpen(row.id)}><Icon style={{fontSize : 20}}>delete</Icon></div>
        :""}

      </div>
    );
  }
  editRow = (row:any) => {
    Store.myUser = {
      id: row.id,
      civility: row.civility,
      lastname: row.lastname,
      firstname: row.firstname,
      matricule: row.matricule,
      email: row.email,
      login: row.login,
      password: row.password,
      id_service: row.id_terme_service, // john
      id_fonction: row.id_terme_fonction, // john
      id_profession: row.id_terme_profession, // john
      typeUserLydoc: row.typeUserLydoc,
      formAdmin:row.formAdmin,
      typeUser: row.typeUser,
      accessConsult: row.accessConsult, // john
      accessRef: row.accessRef, // john
      accessParam: row.accessParam,
      accessSignalement: row.accessSignalement,
      id_fonctionResponsable: { ActionReferent: [], InfoResponsable: [] }, // thomas
      id_professionResponsable: { ActionReferent: [], InfoResponsable: [] }, // Thomas
      id_serviceResponsable: { ActionReferent: [], InfoResponsable: [] }, // thomas
      id_riskResponsable: { ActionReferent: [], InfoResponsable: [] } // thomas */
    };
    Store.contextualmenu.content = "editUser";
    Store.contextualmenu.open = "extended";
    // axios
    //   .get(Store.wsPath + "/1/users/getUserById", { params: { id: id } })
    //   .then(response => {
        
       
    //   });
  };

  deleteRow = (id: number) => {
    axios.post(Store.wsPath + "/1/users/SetStatusUserbyId", {
        id: id
      }).then(response => {
        this.setState({ open: false, id: 0 });
        if (response.data === "Utilisateur supprimé") {
          // Snack bar params
          Store.snackBar.open = true;
          Store.snackBar.message = "L'utilisateur à bien été supprimé";
          getAllUsers();
        }
        else {
          // Snack bar params
          Store.snackBar.open = true;
          Store.snackBar.message = "Un problème est survenu";
          Store.snackBar.error = true;
        }
      });
  };
  modalClose = () => {
    this.setState({ open: false });
  };
  modalOpen = (id?: number) => {
    this.setState({ open: true, id: id });
  };

  addUser = () => {
    Store.myUser = Store.DefaultMyUser;
    Store.contextualmenu.content = "addUser";
    Store.contextualmenu.open = "extended";
  }
  public render() {
    const options = {
      sizePerPage: 25
    }
    return (
      <div className={styles.contentListUsers}>
        <div className={styles.usersTitle} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Utilisateurs :</h5></div>

        {(Store.userConnected.typeUserLydoc == "admin" || Store.userConnected.typeUserLydoc == "ged") && Store.authMode != "AAD" && Store.authMode != "AD"?
        <div className={styles.buttonUserContent}>
         <Button
            onClick={this.addUser}
            variant="contained"
            color="primary"
          >
            <Icon>add</Icon> Ajouter un utilisateur
      </Button>
      </div>
          : ""}

        {Store.allUsers ?
          <div className={styles.usersContent}>
            <BootstrapTable
              multiColumnSearch
              data={Store.allUsers}
              searchPlaceholder="Rechercher "
              search={true}
              pagination
              version="4"
              ref="table"
              options={options}
            >
              <TableHeaderColumn isKey={true} hidden dataField="id">
                Id
              </TableHeaderColumn>
              {/* <TableHeaderColumn headerAlign='center' dataAlign='center' width='10%' dataSort={true} dataField="civility">
                Civilité
              </TableHeaderColumn> */}
              <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="lastname">
                Nom
                </TableHeaderColumn>
              <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="firstname">
                Prénom
                </TableHeaderColumn>
              {/* <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} tdStyle={{ whiteSpace: 'normal' }} dataField="profession">
                Profession
                </TableHeaderColumn> */}
              {/* <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} tdStyle={{ whiteSpace: 'normal' }} dataField="service">
                Service
                
                </TableHeaderColumn> */}
                <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="email">
                Email
              </TableHeaderColumn>
              <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="login">
                Login
              </TableHeaderColumn>
              <TableHeaderColumn headerAlign='center' width={Store.userConnected.typeUserLydoc == "admin" ?'10%':'5%'} dataField='id' tdStyle={{padding: '0', verticalAlign : 'inherit' }} dataFormat={this.toolbox.bind(this)}>Actions</TableHeaderColumn>
            </BootstrapTable>
          </div>
          : ""}

        <Modal
          open={this.state.open}
          className={modal.modal}
          onClose={this.modalClose}
        >
          <div className={modal.modalContent}>
            <Typography variant="h5" id="modal-title">
              Suppression
            </Typography>
            <br />
            <Typography variant="subtitle2" id="simple-modal-description">
              Vous allez supprimer cet utilisateur, êtes vous sûres
              de cette action ?
            </Typography>
            <br />
            <div className={modal.modalButton}>
              
              <Button
                onClick={this.modalClose}
                className={modal.button}
                variant="contained"
                color="secondary"
              >
                Annuler
              </Button>
              <Button
                className={modal.button}
                onClick={() => this.deleteRow(this.state.id)}
                variant="contained"
                color="primary"
              >
                Valider
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
